.welcome {
    position: fixed;
    height: 100dvh;
    width: 100dvw;
    /*General Settings*/
    text-align: center;
    background-color: var(--md-sys-color-surface);
    transition: background-color 0.3s, transform 0.3s;
    --md-ref-typeface-brand: Roboto;
    --md-ref-typeface-plain: Roboto;
    --border-color: var(--md-sys-color-outline);
    color: var(--md-sys-color-on-surface);
    overflow-x: hidden;
    overflow-y: visible;
}
.welcome-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    position: sticky;
    top: 0;
    background-color: var(--md-sys-color-surface-container);
    z-index: 1000;
    padding: 10px 0;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    font-size: 1.25rem;
    padding-right: 1rem;
    padding-left: 1rem;
}

.home-button,
.navigation-links a {
    text-decoration: none;
    color: var(--md-sys-color-primary);
    margin: 0 10px;
}

.home-button:hover,
.navigation-links a:hover {
    text-decoration: underline;
}

.navigation-links {
    margin-left: auto;
}

.home-button {
    font-size: 1.5rem;
    font-weight: bold;
}

.welcome h2 {
    margin-bottom: 30px;
    font-size: 28px;
    color: var(--md-sys-color-primary); /* Primary color for headings */
}

/* Hero Section with Color Variables */
.hero-section {
    text-align: center;
    padding: 80px 20px; /* Adjust padding as needed for your layout */
    background-color: var(--md-sys-color-primary-container); /* Primary color background */
    color: var(
        --md-sys-color-on-primary-container
    ); /* Text color that contrasts with the primary background */
}

.hero-section h1 {
    font-size: 2.5rem; /* Large, attention-grabbing size */
    margin-bottom: 30px; /* Spacing between the heading and the paragraph */
}

.hero-section p {
    font-size: 1.3rem; /* Comfortable reading size for the paragraph */
    margin: 10px;
}
.hero-section md-filled-button {
    margin-top: 30px; /* Spacing between the paragraph and the CTA button */
}
.cta-button {
}

.cta-button:hover {
}

/* How It Works Section with Color Variables */
.how-it-works-section {
    text-align: center;
    padding: 50px 20px;
    background-color: var(
        --md-sys-color-surface
    ); /* Using surface color for section background */
    color: var(--md-sys-color-on-surface); /* Text color on surface */
}

.steps-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
}

.step {
    width: 300px;
    padding: 20px;
    background-color: var(
        --md-sys-color-surface-container
    ); /* Lighter shade for step backgrounds */
    box-shadow: 0 2px 4px var(--md-sys-color-shadow); /* Shadow color */
    border-radius: 8px;
    transition: transform 0.3s ease;
}

.step:hover {
    transform: translateY(-10px);
}

.step-icon {
    height: 60px;
    width: 60px;
    margin: auto;
    margin-bottom: 20px;
    background-color: var(
        --md-sys-color-primary-container
    ); /* Primary container color for icons */
    color: var(--md-sys-color-on-primary-container); /* Text/icon color on primary container */
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
}

.step h3 {
    margin-bottom: 10px;
    font-size: 22px;
    min-height: 60px;
    align-content: center;
    color: var(--md-sys-color-on-surface); /* Maintaining readability on step backgrounds */
}

.step p {
    font-size: 16px;
    color: var(--md-sys-color-on-surface-variant); /* Slightly lighter text for descriptions */
    line-height: 1.5;
}

.app-clarification-section {
    padding: 40px;
}
.clarification-wrapper {
    display: flex;
    align-items: stretch;
    background-color: var(--md-sys-color-background);
    color: var(--md-sys-color-on-background);

    flex-wrap: wrap;
    gap: 1rem;
    justify-content: center;
}

.clarification {
    display: flex;
    flex-direction: column;
    background-color: var(--md-sys-color-surface-container);
    color: var(--md-sys-color-on-surface);
    margin: 0.75rem 0;
    padding: 20px;
    border-radius: 8px;
    width: 80%;
    justify-content: stretch;
    max-width: 500px;
    min-width: 300px;
}

.clarification h3 {
    color: var(--md-sys-color-primary);
    margin-top: 0;
    margin-bottom: 10px;
}

.clarification p {
    min-height: 4rem;
    align-content: center;
    color: var(--md-sys-color-on-surface);
}
.philosophy-section {
    display: flex;
    align-items: stretch;
    margin-bottom: 80px;
    gap: 30px;
    justify-content: center;
    flex-wrap: wrap;
}

.learning-philosophy,
.development-philosophy {
    padding: 2rem;
    width: 500px;
    border-radius: 50px;
}
.learning-philosophy li,
.development-philosophy li {
    list-style-position: outside;
    text-align: left;
    margin-bottom: 1rem;
}

.learning-philosophy {
    background-color: var(--md-sys-color-secondary-container);
    color: var(--md-sys-color-on-secondary-container);
}
.learning-philosophy h2 {
    color: var(--md-sys-color-on-secondary-container);
}

.development-philosophy {
    background-color: var(--md-sys-color-tertiary-container);
    color: var(--md-sys-color-on-tertiary-container);
}
.development-philosophy h2 {
    color: var(--md-sys-color-on-tertiary-container);
}

.testimonials-section {
    background-color: var(--md-sys-color-background);
    color: var(--md-sys-color-on-background);
    padding: 40px 20px;
    text-align: center;
}

.testimonials-title {
    color: var(--md-sys-color-primary);
    margin-bottom: 30px;
}

.testimonials-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
    justify-content: center;
    align-items: center;
}

.testimonial {
    background-color: var(--md-sys-color-surface);
    color: var(--md-sys-color-on-surface);
    border: 1px solid var(--md-sys-color-outline);
    border-radius: 8px;
    padding: 20px;
    max-width: 600px;
}

.testimonial-text {
    font-style: italic;
    color: var(--md-sys-color-on-secondary-container);
}

.testimonial-author {
    margin-top: 20px;
    color: var(--md-sys-color-on-primary-container);
    font-weight: bold;
}

.interactive-demo-section {
    background-color: var(--md-sys-color-background);
    color: var(--md-sys-color-on-background);
    padding: 20px;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
}

.interactive-demo-section p {
    color: var(--md-sys-color-on-surface-variant);
}

.demo-input {
    padding: 10px;
    border-radius: 5px;
    border: 1px solid var(--md-sys-color-outline);
    background-color: var(--md-sys-color-surface);
    color: var(--md-sys-color-on-surface);
    width: 100%;
    max-width: 400px;
}

.demo-submit-button {
    background-color: var(--md-sys-color-primary);
    color: var(--md-sys-color-on-primary);
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.demo-submit-button:hover {
    background-color: var(--md-sys-color-primary-container);
}

.demo-output {
    margin-top: 20px;
    background-color: var(--md-sys-color-surface-variant);
    color: var(--md-sys-color-on-surface-variant);
    padding: 15px;
    border-radius: 5px;
    width: 100%;
    max-width: 600px;
}
.pricing-section {
    background-color: var(--md-sys-color-background);
    color: var(--md-sys-color-on-background);
    padding: 40px;
    text-align: center;
}

.plans-container {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-top: 20px;
}

.plan {
    background-color: var(--md-sys-color-surface);
    border: 1px solid var(--md-sys-color-outline);
    border-radius: 8px;
    padding: 20px;
    width: 300px;
}

.plan h3 {
    background-color: var(--md-sys-color-primary);
    color: var(--md-sys-color-on-primary);
    padding: 10px;
    border-radius: 8px;
}

.plan p {
    color: var(--md-sys-color-on-surface);
}

.plan ul {
    list-style: none;
    padding: 0;
    text-align: left;
}

.plan ul li {
    background-color: var(--md-sys-color-surface-variant);
    color: var(--md-sys-color-on-surface-variant);
    padding: 5px;
    margin: 10px 0;
    border-radius: 4px;
}

.plan button {
    background-color: var(--md-sys-color-primary-container);
    color: var(--md-sys-color-on-primary-container);
    padding: 10px 20px;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.plan button:hover {
    background-color: var(--md-sys-color-primary);
}

.faq-section {
    background-color: var(--md-sys-color-surface);
    color: var(--md-sys-color-on-surface);
    padding: 2rem;
}

.faq-heading {
    color: var(--md-sys-color-primary);
    text-align: center;
    margin-bottom: 1.5rem;
}

.faq-container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    max-width: 800px;
    margin: auto;
}

.faq-item {
    background-color: var(--md-sys-color-surface-container);
    border-left: 4px solid var(--md-sys-color-primary);
    padding: 1rem;
    border-radius: 0.5rem;
}

.faq-question {
    color: var(--md-sys-color-on-primary-container);
    margin: 15px;
    margin-bottom: 0.5rem;
    text-align: left;
}

.faq-answer {
    color: var(--md-sys-color-on-surface-variant);
    margin: 15px;
    text-align: left;
}
.cta-section {
    background-color: var(--md-sys-color-background);
    text-align: center;
    padding: 50px 20px;
}

.cta-heading {
    color: var(--md-sys-color-on-background);
    margin-bottom: 20px;
    font-size: 24px;
}
.footer {
    background-color: var(--md-sys-color-surface);
    color: var(--md-sys-color-on-surface);
    padding: 20px;
    text-align: center;
}

.footer-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
}

.footer-nav a,
.social-media a {
    color: var(--md-sys-color-primary);
    text-decoration: none;
    margin: 0 10px;
}

.social-media {
    display: flex;
    justify-content: center;
    gap: 20px;
}

.newsletter-signup input[type="email"] {
    padding: 10px;
    margin-right: 10px;
    border: 1px solid var(--md-sys-color-outline);
    background-color: var(--md-sys-color-surface);
    color: var(--md-sys-color-on-surface);
}

.newsletter-signup button {
    padding: 10px 20px;
    border: none;
    background-color: var(--md-sys-color-primary);
    color: var(--md-sys-color-on-primary);
    cursor: pointer;
}

.newsletter-signup button:hover {
    background-color: var(--md-sys-color-primary-container);
}

.construction-wrap {
    position: relative;
    overflow: visible;
    width: 100%;
    display: flex;
    justify-content: center;
    justify-items: center;
    align-content: center;
    align-items: center;
}

.construction-wrap::before {
    content: ""; /* Banner text */
    position: absolute;
    width: 150%; /* Ensure it covers the entire width */
    height: 60px; /* Tape height */
    background: repeating-linear-gradient(
        45deg,
        #000,
        #000 20px,
        #ffb101 20px,
        #ffb101 40px
    ); /* Solid yellow background */
    z-index: 1;
    transform: var(
        --banner-angle,
        rotate(45deg)
    ); /* Fallback to 45 degrees if the variable is not set */
    /* Apply the border using the gradient and simulate it as the tape's edges */
}

.construction-wrap::after {
    content: "UNDER CONSTRUCTION"; /* Banner text */
    position: absolute;
    transform: var(
        --banner-angle,
        rotate(45deg)
    ); /* Fallback to 45 degrees if the variable is not set */
    background-color: #ffb101; /* Solid yellow background */
    font-size: 30px; /* Adjust as needed */
    color: black; /* Text color */
    font-weight: bold; /* Make text bold */
    text-align: center;
    padding: 0 10px; /* Add some padding around the text */
    z-index: 2; /* Ensure the text is on top of the background */
    border: 2px solid black;
}
