:root {
    --padding-standard: 0.35rem;
    --margin-standard: 0.2rem;
    --border-radius-standard: 10px;
    --font-style-italic: italic;
    --font-size-standard: 1rem;
    --font-size-small: 0.9rem;
    --md-icon-button-icon-size: 2rem;
    --md-icon-button-state-layer-height: calc(var(--md-icon-button-icon-size) * 1.25);
    --md-icon-button-state-layer-width: calc(var(--md-icon-button-icon-size) * 1.25);
    --text-color-main: var(--md-sys-color-on-surface);
}

@import url("https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL@20..48,100..700,0..1");
@import url("https://fonts.googleapis.com/css2?family=Material+Symbols+Rounded:opsz,wght,FILL@20..48,100..700,0..1");
@import url("https://fonts.googleapis.com/css2?family=Material+Symbols+Sharp:opsz,wght,FILL@20..48,100..700,0..1");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200");

* {
    box-sizing: border-box; /* Ensures padding and border are included within the element's width and height */
}

body {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial,
        sans-serif;
}

.app {
    position: fixed;
    height: 100dvh;
    width: 100dvw;
    /*General Settings*/
    text-align: center;
    background-color: var(--md-sys-color-surface);
    transition: background-color 0.3s, transform 0.3s;
    display: flex;
    flex-direction: column;
    /* --md-filled-button-container-shape: 10px; */
    --md-ref-typeface-brand: Roboto;
    --md-ref-typeface-plain: Roboto;
    /*Colors*/
    --background-color: var(--md-sys-color-surface-container);
    --message-text-color: var(--md-sys-color-on-surface);
    --transliteration-background-color: var(
        --md-sys-color-primary-container
    ); /* Example value */
    --transliteration-text-color: var(--md-sys-color-on-primary-container); /* Example value */
    --translated-word-text-color: var(--md-sys-color-primary);
    --translation-grid-background-color: var(--md-sys-color-surface-container);
    /* Containers for Background Colors */
    --border-color: var(--md-sys-color-outline);
    overflow: hidden;
    color: var(--md-sys-color-on-surface);
}

.app-container {
    height: 100dvh;
    width: 100%;
    display: flex;
    flex-direction: column;
    max-width: 40rem;
    margin-left: auto;
    margin-right: auto;
    position: relative;
}

.app-header {
    display: flex;
    align-items: center; /* Vertically centers items in the header */
    justify-content: flex-end; /* Aligns items to the end (right side) */
}

.app-header .navigation-menu-button {
    margin-right: auto;
}

.app-header .conversation-options-menu-button {
    position: relative;
    border-radius: 50%;
}

.scenario-goals-button {
    position: absolute;
    right: 0;
    top: 25%;
    z-index: 1;
    --md-icon-button-icon-size: 2rem;
    --md-icon-button-state-layer-height: calc(var(--md-icon-button-icon-size) * 1.25);
    --md-icon-button-state-layer-width: calc(var(--md-icon-button-icon-size) * 1.25);
}

.pulse::before {
    opacity: 0;
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    animation: pulseAnimation 1.25s infinite;
    z-index: 0;
    background-color: var(--md-sys-color-on-surface-variant);
}

.pulse-round::before {
    border-radius: 50%;
}

@keyframes pulseAnimation {
    0% {
        opacity: 0;
        transform: scale(0); /* Start and end scaled down */
    }

    20% {
        opacity: 0.35; /* Quickly become fully visible */
        transform: scale(1); /* Scale to full size */
    }

    100% {
        opacity: 0; /* Fade out */
    }
}
.conversation-area {
    position: relative;
    padding: 10px;
    border: 1px solid var(--border-color);
    width: 100%;
    height: 100%;
    margin-bottom: 10px;
    overflow: hidden;
}

.scroll-wrapper {
    overflow-y: auto;
    display: flex;
    flex-grow: 1;
    flex-shrink: 1;
    flex-direction: column-reverse;
    height: 100%;
    overflow-anchor: none;
}

.message-list {
    justify-content: flex-end;
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    max-height: 100%;
}

.message-options-container {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 10px;
    padding-top: 15px;
    pointer-events: none;
    background-color: var(--md-sys-color-surface-container);
    border-top: 1px solid var(--border-color);
    display: flex;
    flex-direction: column; /* Stack children vertically */
    justify-content: flex-start; /* Adjust as needed */
    align-items: stretch; /* Adjust as needed */
    animation: bounceUp 0.2s ease-out forwards;
    pointer-events: all;
    gap: 5px; /* Adds space between children */
}

.message-options-container.visible {
    transform: translateY(0); /* Settle at the final position when visible */
}

.message-options-container:not(.visible) {
    transform: translateY(100%); /* Start below the view when hidden */
    animation: none; /* Disable animation when not visible */
}

@keyframes bounceUp {
    0% {
        transform: translateY(100%); /* Start below the view */
    }
    70% {
        transform: translateY(-2.5%); /* Overshoot upwards */
    }
    100% {
        transform: translateY(0); /* Settle at the final position */
    }
}

.message {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    position: relative;
    margin: var(--margin-standard);
    padding: var(--padding-standard);
    border-radius: var(--border-radius-standard);
    transition: all 0.3s ease;
    background-color: var(--background-color);
    color: var(--message-text-color);
    text-align: left;

    -webkit-touch-callout: none; /* Disable callout menu on iOS */
    -webkit-user-select: none; /* Disable text selection on iOS */
    -moz-user-select: none; /* Disable text selection on Firefox */
    -ms-user-select: none; /* Disable text selection on IE */
    user-select: none; /* Disable text selection */

    -webkit-tap-highlight-color: transparent; /* Safari and Chrome on iOS */
}

.message.user {
    align-self: flex-end;
    margin-left: 50px;
    justify-content: right;
}

.message.assistant {
    text-align: left;
    margin-right: 50px;
    align-self: flex-start;
    justify-content: left;
}

/* Increase specificity by combining selectors */
.message.user.message-expanded,
.message.assistant.message-expanded {
    align-self: stretch;
    margin-left: 0px;
    margin-right: 0px;
    cursor: default;
}

.message.developer {
    background-color: var(--md-sys-color-surface-container-low);
    color: var(--md-sys-color-on-surface-variant);
    align-self: flex-start;
    justify-content: left;
}

.rounded {
    --md-icon-font: "Material Symbols Rounded";
}

.sharp {
    --md-icon-font: "Material Symbols Sharp";
}

.md-icon[filled] {
    font-variation-settings: "FILL";
}
.md-menu {
    overflow: auto;
}

.scenario-manager md-dialog{
    min-width: fit-content;
}
.scenario-manager-text-field {
    display: flex;
    align-items: center; /* This aligns items vertically in the center */
    justify-content: space-between; /* This separates the text field and button */
    min-width: 25dvw;
}
.scenario-manager-text-field md-filled-text-field {
    flex-grow: 1; /* This makes the text field take up the available space */
    resize: vertical;
}

.translation-grid {
    display: flex;
    flex-wrap: wrap;
}

.translation-grid.message-expanded {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.message-field {
    border-radius: var(--border-radius-standard);
    margin: var(--margin-standard);
    padding: var(--padding-standard);
    color: var(--text-color-main);
    font-size: var(--font-size-standard);
}

.message-field .error {
    color: var(--md-sys-color-error);
}

.message-field .warning {
    color: orange;
}

.original-message {
    color: var(--md-sys-color-on-surface);
    font-style: italic;
}

.full-sentence-translation {
    background-color: var(--md-sys-color-secondary-container);
    color: var(--md-sys-color-on-secondary-container);
}

.message-form {
    margin-bottom: auto;
    display: flex;
    justify-content: center;
    align-items: center; /* Align children vertically in the middle */
    flex-direction: row;
    padding: 0px;
    width: 100%;
    font-family: Roboto;
    flex-shrink: 0; /* Prevent shrinking */
    flex-grow: 0; /* Prevent growing, ensuring it takes only the required space */
}

.message-form md-outlined-text-field {
    flex-grow: 1;
    resize: none;
    overflow: visible;
    text-align: left;
    ime-mode: active;
}

.message-form md-icon-button {
    flex-shrink: 0; /* Prevents the button from shrinking */
    margin-right: 10px;
    margin-left: 10px;
    /* Add specific width/height if needed */
}

.translation-pair {
    display: inline-grid;
    grid-template-rows: auto auto auto; /* Adjusted to accommodate 3 rows */
    align-items: start; /* Aligns items to the start of the container */
    margin: 0.1rem;
}
.translation-pair.message-expanded {
    min-width: 5rem;
    min-height: 3rem;
    justify-items: center;
    padding: 0.75rem;
    cursor: pointer;
}

.translation-pair.message-expanded.selected-morpheme {
    background-color: var(--md-sys-color-surface-container-high);
    border-radius: 1rem;
}

.original-word,
.translated-word,
.transliteration-word {
    margin: 0;
    padding: 0;
    line-height: 1.3rem;
    text-align: left;
    white-space: nowrap;
    height: 1.3rem;
}

.original-word {
    font-size: var(--font-size-standard);
    color: var(--text-color-main);
}

.translated-word {
    font-style: italic;
    font-size: var(--font-size-small);
    color: var(--translated-word-text-color);
}

.transliteration-word {
    font-weight: bold;
    color: var(--md-sys-color-tertiary);
    font-size: var(--font-size-small);
}

.conversation-history {
    background-color: var(--md-sys-color-surface-container);
    color: var(--md-sys-color-on-surface);
    text-align: left;
    margin-top: 20px;
    padding: 10px;
    overflow: auto;
    max-height: 300px;
    max-width: 900px;
    margin: auto;
}

.rtl {
    direction: rtl;
    text-align: right;
}

.ltr {
    direction: ltr;
    text-align: left;
}

.morpheme-menu {
    display: flex;
    flex-direction: column; /* Stack children vertically */
    justify-content: flex-start; /* Adjust as needed */
    align-items: stretch; /* Adjust as needed */
    gap: 5px; /* Adds space between children */
    color: var(--md-sys-color-on-primary-container);
    border-radius: var(--border-radius-standard);
    margin: var(--margin-standard);
    padding: var(--padding-standard);
}
.morpheme-menu .morpheme-options {
    background-color: red;
}
.morpheme-menu .morpheme-buttons {
    background-color: var(--md-sys-color-primary-container);
    border-radius: 2rem;
    padding: 0.25rem;
    --button-margin: 0.25rem;
}

.morpheme-menu .cancel-button {
    --md-icon-button-icon-color: var(--md-sys-color-error);
    --md-icon-button-hover-icon-color: var(--md-sys-color-error);
    margin-left: var(--button-margin);
    margin-right: var(--button-margin);
}
.morpheme-menu .confirm-button {
    --md-icon-button-icon-color: var(--md-sys-color-on-primary-container);
    --md-icon-button-hover-icon-color: var(--md-sys-color-on-primary-container);
    margin-left: var(--button-margin);
    margin-right: var(--button-margin);
}

.conversation-options md-dialog {
    max-height: 100%;
}
.dialog-close-button {
    margin-left: auto;
}

.headline {
    margin-right: 0.5rem;
    margin-left: 0.5rem;
}
.scenario-goals-content {
    font-size: 1rem;
    margin: 1rem;
}

/* Apply to elements with the 'scrollable' class */
.scrollable {
    scrollbar-width: thin;
    scrollbar-color: var(--md-sys-color-on-surface-variant) var(--md-sys-color-surface);
    overflow-y: auto; /* Enables vertical scrolling */
}

.scrollable::-webkit-scrollbar {
    width: 8px;
    height: 8px;
}

.scrollable::-webkit-scrollbar-track {
    background: var(--md-sys-color-surface);
}

.scrollable::-webkit-scrollbar-thumb {
    background-color: var(--md-sys-color-on-surface-variant);
    border-radius: 4px;
}

.scrollable::-webkit-scrollbar-thumb:hover {
    background-color: var(--md-sys-color-on-surface-variant);
}
