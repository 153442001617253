.word-bank .word-bank-title {
    font-size: 1.5rem;
    color: var(--md-sys-color-primary);
    font-weight: bold;
    margin: 10px;
}

.word-bank .word-bank-area {
    display: flex;
    position: relative;
    flex-direction: column;
    flex-grow: 1;
    border: 1px solid var(--border-color);
    margin-bottom: 10px;
    padding: 10px;
    overflow: hidden;
}

.word-bank .word-bank-table-container {
    overflow-y: auto;
    height: auto;
    border: 1px solid var(--border-color);
    margin-top: 10px;
}

.word-bank .word-bank-table {
    display: table;
    width: 100%;
    border-spacing: 0;
    overflow-y: auto;
}

.word-bank-header-row {
    display: table-row;
    background-color: var(--md-sys-color-primary-container);
    color: var(--md-sys-color-on-primary-container);
}

.word-bank-row {
    display: table-row;
    cursor: pointer;
    background-color: var(--md-sys-color-surface-container-low);
    color: var(--md-sys-color-on-surface);
}

.word-bank-row.selected {
    background-color: var(--md-sys-color-surface-container-high);
    color: var(--md-sys-color-on-secondary-container);
    font-weight: bold;
}

.word-bank-header-item,
.word-bank-item {
    display: table-cell;
    padding: 10px;
    border: 1px solid var(--border-color);
    text-align: center;
}

.word-bank-header-row .word-bank-header-item {
    font-weight: bold;
    background-color: var(--header-background-color);
}

.word-bank .popup-menu {
    position: absolute;
    background-color: var(--md-sys-color-surface-container-high);
    bottom: 0;
    left: 0;
    right: 0;
    color: var(--md-sys-color-on-surface);
    border: 1px solid #ccc;
    padding: 10px;
    display: flex;
    justify-content: space-around;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transform: translateY(100%);
    animation: popup 0.5s forwards;
    margin-top: auto;
}

@keyframes popup {
    from {
        transform: translateY(100%);
    }
    to {
        transform: translateY(0);
    }
}

.word-bank .popup-menu.close {
    animation: popdown 0.5s forwards;
}

@keyframes popdown {
    from {
        transform: translateY(0);
    }
    to {
        transform: translateY(100%);
    }
}
