.login-register {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100dvh;
    margin: 0;
}
.login-register-container {
    text-align: center;
    padding: 40px;
    border-radius: 8px;
    border: 3px solid var(--md-sys-color-surface-variant);
    width: 100%;
    max-width: 400px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}
.login-form {
    display: flex;
    flex-direction: column;
    max-width: 24rem;
    align-items: center;
  }
.input-container {
    margin-bottom: 12px;
}
.input-container md-outlined-text-field {
    width: 14rem;
}
.checkbox-container {
    display: flex;
    align-items: center;
    gap: 1rem;
  }

.form-actions {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.login-register-container a {
    color: var(--md-sys-color-on-primary-container);
    text-decoration: none;
    display: block;
    margin: 15px 0;
}

.login-register-container span {
    font-size: 14px;
}

.error-message {
    color: var(--md-sys-color-error);
    font-size: 0.9rem;
    margin-top: 10px; /* Adjust based on your layout */
    text-align: center;
}
