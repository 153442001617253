/* ConversationSidePanelContent.css */

.side-panel-content {
    display: flex;
    flex: 1; /* Ensures it takes up remaining space */
    flex-direction: column;
}

.h3-select-conversation {
    display: flex;
    position: relative;
    flex-direction: row;
    align-items: center;
    margin-bottom: 1rem;
}

.h3-select-conversation span {
    margin: 0 auto;
    text-align: center;
    flex-grow: 1;
}

.h3-select-conversation md-icon-button {
    position: absolute;
    right: 0;
    --md-icon-button-icon-size: 1.7rem;
}

.app-side-panel li md-icon-button {
    position: relative;
    margin-left: auto;
    opacity: 0;
    --md-icon-button-icon-size: 2rem;
    height: 2rem;
    width: 2rem;
    transition: opacity 0.2s ease;
}

.app-side-panel li:hover md-icon-button,
.app-side-panel li.selected md-icon-button {
    opacity: 1;
}
