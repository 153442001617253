.dark {
  --md-sys-color-primary: rgb(135 209 235);
  --md-sys-color-surface-tint: rgb(135 209 235);
  --md-sys-color-on-primary: rgb(0 53 67);
  --md-sys-color-primary-container: rgb(0 78 95);
  --md-sys-color-on-primary-container: rgb(181 235 255);
  --md-sys-color-secondary: rgb(179 202 212);
  --md-sys-color-on-secondary: rgb(29 51 59);
  --md-sys-color-secondary-container: rgb(52 74 82);
  --md-sys-color-on-secondary-container: rgb(207 230 240);
  --md-sys-color-tertiary: rgb(194 195 235);
  --md-sys-color-on-tertiary: rgb(43 46 77);
  --md-sys-color-tertiary-container: rgb(66 68 101);
  --md-sys-color-on-tertiary-container: rgb(224 224 255);
  --md-sys-color-error: rgb(255 180 171);
  --md-sys-color-on-error: rgb(105 0 5);
  --md-sys-color-error-container: rgb(147 0 10);
  --md-sys-color-on-error-container: rgb(255 218 214);
  --md-sys-color-background: rgb(15 20 22);
  --md-sys-color-on-background: rgb(222 227 230);
  --md-sys-color-surface: rgb(15 20 22);
  --md-sys-color-on-surface: rgb(222 227 230);
  --md-sys-color-surface-variant: rgb(64 72 75);
  --md-sys-color-on-surface-variant: rgb(191 200 204);
  --md-sys-color-outline: rgb(137 146 150);
  --md-sys-color-outline-variant: rgb(64 72 75);
  --md-sys-color-shadow: rgb(0 0 0);
  --md-sys-color-scrim: rgb(0 0 0);
  --md-sys-color-inverse-surface: rgb(222 227 230);
  --md-sys-color-inverse-on-surface: rgb(44 49 52);
  --md-sys-color-inverse-primary: rgb(3 103 126);
  --md-sys-color-primary-fixed: rgb(181 235 255);
  --md-sys-color-on-primary-fixed: rgb(0 31 40);
  --md-sys-color-primary-fixed-dim: rgb(135 209 235);
  --md-sys-color-on-primary-fixed-variant: rgb(0 78 95);
  --md-sys-color-secondary-fixed: rgb(207 230 240);
  --md-sys-color-on-secondary-fixed: rgb(7 30 38);
  --md-sys-color-secondary-fixed-dim: rgb(179 202 212);
  --md-sys-color-on-secondary-fixed-variant: rgb(52 74 82);
  --md-sys-color-tertiary-fixed: rgb(224 224 255);
  --md-sys-color-on-tertiary-fixed: rgb(22 25 55);
  --md-sys-color-tertiary-fixed-dim: rgb(194 195 235);
  --md-sys-color-on-tertiary-fixed-variant: rgb(66 68 101);
  --md-sys-color-surface-dim: rgb(15 20 22);
  --md-sys-color-surface-bright: rgb(52 58 60);
  --md-sys-color-surface-container-lowest: rgb(10 15 17);
  --md-sys-color-surface-container-low: rgb(23 28 31);
  --md-sys-color-surface-container: rgb(27 32 35);
  --md-sys-color-surface-container-high: rgb(37 43 45);
  --md-sys-color-surface-container-highest: rgb(48 54 56);
}
