/* WordBankSidePanelContent.css */
.word-bank-side-panel h3 {
    margin-top: 1rem;
    color: var(--md-sys-color-secondary);
}

.word-bank-side-panel ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.word-bank-side-panel li {
    padding: 10px 15px;
    cursor: pointer;
    border-bottom: 1px solid var(--md-sys-color-on-surface);
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.word-bank-side-panel li:hover,
.word-bank-side-panel li.selected {
    background-color: var(--md-sys-color-surface-container-high);
}

/* Additional WordBank-specific styles can be added here */
