.dashboardContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100vh; /* Make the container take full viewport height */
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px; /* Optional padding */
    box-sizing: border-box;
    overflow: hidden; /* Hide any overflow to fit the viewport */
    border: 1px solid var(--border-color);
    color: var(--md-sys-color-on-surface);
}

.chartWrapper {
    width: 100%;
    height: 50%; /* Make the chart take half the height of the container */
    overflow-x: auto; /* Allow horizontal scrolling for the chart */
}
